import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "./Icons";
import UserWithdrawsList from "./UserWithdrawsList";

const UserWithdraws = ({ user }) => {
  const navigate = useNavigate();

  const tryBack = () => {
    // navigate(-1);
    navigate("/dashboard");
  };

  useEffect(() => {
    dayjs.locale("ru");
  }, []);

  return (
    <div>
      <div className="main__head mb-16 ">
        <div className="text text_xl">Вывод средств</div>
        <div className="main__head-back" onClick={tryBack}>
          <BackIcon />
          <span>Назад</span>
        </div>
      </div>
      <div className="project-details">
        <UserWithdrawsList user={user} />
      </div>
    </div>
  );
};

export default UserWithdraws;
