import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { formatFinance } from "../common/utils";
import Pagination from "./Pagination";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Spinner from "./spinner/Spinner";
import { AddPlusIcon, InfoIcon } from "./Icons";
import useDayjsFormat from "../hooks/useDayjsFormat";
import { toast } from "react-toastify";
import ModalInfo from "./modal/ModalInfo";
import ModalWithdraw from "./modal/ModalWithdraw";

const UserWithdrawsList = (props) => {
  const { user } = props;
  const [pageWithdrawals, setpageWithdrawals] = useState(1);
  const [optionValue, setOptionValue] = useState(10);
  const [valueSearchState, setValueSearchState] = useState("");
  const [optionValueStatus, setOptionValueStatus] = useState("");
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [cancelId, setCancelId] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    title: "Комментарий",
    note: "",
  });
  const [modalActive, setModalActive] = useState(false);
  // const [paymentId, setPaymentId] = useState(null);

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: `/merchant/withdraws/list`,
      method: "POST",
    },
    axiosConfig
  );
  const [{ data: dataCancel, loading: loadingCancel }, executePostCancel] =
    useAxios(
      {
        url: `/merchant/shop/withdraws/${cancelId}/cancel`,
        method: "POST",
      },
      axiosConfig
    );
  useEffect(() => {
    executePost({
      data: {
        page: pageWithdrawals,
        count: optionValue,
        status: optionValueStatus,
        date_start: dateStart,
        date_end: dateEnd,
        order_id: valueSearchState,
      },
    }).catch((error) => {
      // console.log("executePost", error);
    });
    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [
    executePost,
    pageWithdrawals,
    optionValue,
    optionValueStatus,
    dateStart,
    dateEnd,
    valueSearchState,
    dataCancel,
    modalActive,
  ]);

  const options = [
    { id: 1, value: 10 },
    { id: 2, value: 25 },
    { id: 3, value: 50 },
    { id: 4, value: 100 },
    { id: 5, value: 150 },
  ];

  const optionsStatus = [
    { id: 1, value: null, name: "Любой" },
    { id: 2, value: 2, name: "Успешно" },
    { id: 3, value: 1, name: "В процессе" },
    { id: 4, value: 0, name: "Создан" },
    { id: 5, value: -1, name: "Отменено пользователем" },
    { id: 6, value: -2, name: "Отменено администратором" },
    { id: 7, value: -3, name: "Отменено мерчантом" },
  ];

  const handleChangeOption = (e) => {
    setOptionValue(e.target.value);
    setpageWithdrawals(1);
  };

  const handleChangeStatus = (e) => {
    setOptionValueStatus(e.target.value);
    setpageWithdrawals(1);
  };

  const withdrawStatus = [
    "Отменено мерчантом",
    "Отменено администратором",
    "Отменено пользователем",
    "Создан",
    "В процессе",
    "Успешно",
  ];

  const getWithdrawStatus = (status) => {
    const index = parseInt(status) + 3;
    if (index >= 0 && index < withdrawStatus.length) {
      return withdrawStatus[index];
    }
  };

  const getWithdrawStyle = (status) => {
    const index = parseInt(status);
    if (index === 0) {
      return "btn-innactive btn-xs btn-warning";
    } else if (index > 0) {
      return "btn-innactive btn-xs btn-innactive_success";
    } else {
      return "btn-innactive btn-xs btn-innactive_draft";
    }
  };

  const { getFullDateMsk, getFullTimeMsk } = useDayjsFormat();

  const handleCancel = (id) => {
    setCancelId(id);
  };
  useEffect(() => {
    if (cancelId !== null) {
      executePostCancel()
        .then(() => {
          toast.success("Вывод Отменено");
        })
        .catch((err) => console.log(err));
    }
  }, [cancelId, executePostCancel]);
  function filterSystem(id) {
    let result = data?.systems.find((item) => item.id === id);
    return result ? result.label : "Wallet";
  }
  let pageCountQuery = Math.ceil(data?.count_withdraws / optionValue);

  return (
    <div className="project-details__tab project-details__tab_active">
      <div className="project-details__table">
        <div className="withdraw__add">
          <AddPlusIcon onClick={() => setModalActive(true)} />
        </div>
        <div
          className="project-details__table-filter"
          style={{ alignItems: "center" }}
        >
          <input
            value={valueSearchState}
            className="input"
            type="text"
            placeholder="Поиск"
            onChange={(e) => {
              setValueSearchState(e.target.value);
            }}
          />
          <div
            className="d-flex gap-20"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            <span>Статус</span>
            <select onChange={handleChangeStatus}>
              {optionsStatus.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div
            className="d-flex gap-20"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            Начало
            <input
              onChange={(e) => {
                setDateStart(e.target.value);
              }}
              type="date"
            ></input>
          </div>
          <div
            className="d-flex gap-20"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            Конец
            <input
              onChange={(e) => {
                setDateEnd(e.target.value);
              }}
              type="date"
            ></input>
          </div>
          <div
            className="d-flex gap-20"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            <span>Количество</span>
            <select onChange={handleChangeOption}>
              {options.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        {loading ? (
          <div style={{ marginTop: 50 }}>
            <Spinner />
          </div>
        ) : (
          <AnimatedBlock>
            <table className="fixedtable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Сумма</th>
                  <th>Куда</th>
                  <th>Система</th>
                  <th>Время</th>
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
                {data?.withdraws.map(
                  ({
                    id,
                    amount,
                    amount_to_pay,
                    purse,
                    method_id,
                    createdAt,
                    status,
                    trader_note,
                  }) => (
                    <tr key={id}>
                      <td data-label="ID">{id}</td>
                      <td data-label="Сумма">
                        {formatFinance(amount)}{" "}
                        <sup>{formatFinance(amount_to_pay)} ₽</sup>
                      </td>
                      <td data-label="Куда">{purse}</td>
                      <td data-label="Система">{filterSystem(method_id)}</td>
                      <td data-label="Время">
                        {getFullTimeMsk(createdAt)} <br />
                        {getFullDateMsk(createdAt)}
                      </td>
                      <td data-label="Статус">
                        <span className={getWithdrawStyle(status)}>
                          {getWithdrawStatus(status)}
                        </span>
                      </td>
                      <td data-label={status === 0 ? "Отменить" : "Инфо"}>
                        {
                          <>
                            {status === 0 && (
                              <button
                                className={
                                  loadingCancel
                                    ? "cancel__btn _loading"
                                    : "cancel__btn"
                                }
                                onClick={() => {
                                  if (!loadingCancel) {
                                    handleCancel(id);
                                  }
                                }}
                              >
                                отменить
                              </button>
                            )}
                            {status === -2 && trader_note && (
                              <button
                                className="btn btn-xs btn-success btn_minimal"
                                onClick={() =>
                                  setModalInfo({
                                    ...modalInfo,
                                    open: true,
                                    note: trader_note,
                                  })
                                }
                              >
                                <InfoIcon />
                              </button>
                            )}
                          </>
                        }
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </AnimatedBlock>
        )}
        {loading ? null : (
          <Pagination
            page={pageWithdrawals}
            setPage={setpageWithdrawals}
            pageCountQuery={pageCountQuery}
          />
        )}
        <ModalWithdraw
          active={modalActive}
          setActive={setModalActive}
          info={user}
        />
        <ModalInfo modalInfo={modalInfo} setModalInfo={setModalInfo} />
      </div>
    </div>
  );
};

export default UserWithdrawsList;
