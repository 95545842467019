import React, { useEffect, useState } from "react";
import useAxios, { configure } from "axios-hooks";
import { ChangeVisibleIcon, IconsSelector } from "./Icons";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Spinner from "./spinner/Spinner";
import { toast } from "react-toastify";
import { $authHost, axiosConfig } from "../common/api";
import { formatFinance } from "../common/utils";

const ShopCurrensies = (props) => {
  const { shopId, shop } = props;
  const [nameShop, setNameShop] = useState(shop?.name);
  const [urlCb, setUrlCb] = useState(shop?.url_cb);
  const [urlRes, setUrlRes] = useState(shop?.url_result);
  const [comission, setComission] = useState(shop?.comission);
  const [secretOne, setSecretOne] = useState(shop?.secret1);
  const [changeVisible, setChangeVisible] = useState(false);
  const [updateDisabledList, setUpdateDisabledList] = useState(new Date());

  configure({ axios: $authHost });

  const [{ data, loading }, refetchInfo, cancelInfo] = useAxios(
    {
      url: `/merchant/shop/methods/${shopId}/info`,
      method: "POST",
    },
    axiosConfig
  );

  const [{ loading: loadingDisable }, refetchDisable] = useAxios(
    {
      url: `/merchant/shop/methods/${shopId}/disable`,
      method: "POST",
    },
    axiosConfig
  );

  const [{ loading: loadingSettings }, executeSettings] = useAxios(
    {
      url: `/merchant/shop/${shopId}/settings/edit`,
      method: "POST",
    },
    axiosConfig
  );

  const handleClick = async (group_name) => {
    console.log("handleClick", group_name);
    /*
    refetchDisable({
      data: {
        group_name: group_name,
      },
    })
      .then(() => {
        setUpdateDisabledList(new Date());
      })
      .catch((error) => {
        // console.log("refetchDisable", error);
      });
      */
  };

  const handleClickSave = async (
    name,
    secret1,
    url_api,
    url_result,
    comission
  ) => {
    executeSettings({
      data: {
        name,
        secret1,
        url_api,
        url_result,
        comission,
      },
    })
      .then((data) => {
        // console.log("executeSettings", data);
        toast.success("Настройки магазина успешно изменены");
      })
      .catch((error) => {
        // console.log("refetchDisable", error);
        toast.error(error?.response?.data?.error);
      });
  };

  useEffect(() => {
    refetchInfo({
      data: {},
    }).catch((error) => {
      // console.log("refetchInfo", error);
    });
    return () => {
      cancelInfo();
    };
    // eslint-disable-next-line
  }, [refetchInfo, updateDisabledList]);

  const checkDisabled = (system_group, disabled) => {
    return disabled.includes(system_group) ? false : true;
  };

  return (
    <div className="project-details__tab project-details__tab_active">
      <div className="project-details__table mt-30">
        <div className="project-currencies">
          <div className="project-currencies__table">
            {loading | loadingDisable ? (
              <div style={{ marginTop: 20 }}>
                <Spinner />
              </div>
            ) : (
              <AnimatedBlock>
                <div
                  style={{
                    background: "rgba(29, 33, 41, 0.3)",
                    borderRadius: "20px",
                    padding: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <table className="table no-border">
                    <thead>
                      <tr>
                        <th>Название</th>
                        <th>Лимит</th>
                        <th>Комиссия</th>
                        <th>Доступность</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.methods?.map((info, index) => (
                        <tr key={index}>
                          <td data-label="Название">
                            <div className="td-currency">
                              <IconsSelector
                                icon={info?.system_group}
                                active={true}
                                color={"#fff"}
                              />
                              <div>
                                <div>{info?.system_group}</div>
                                <div className="td-currency__sm"></div>
                              </div>
                            </div>
                          </td>
                          <td data-label="Лимит">
                            {formatFinance(info?.min)} -{" "}
                            {formatFinance(info?.max)} ₽
                          </td>
                          <td data-label="Магазин (%)">{info?.comission} %</td>
                          <td data-label="Доступность">
                            <div className="custom-switch">
                              <input
                                type="checkbox"
                                id="requisite-available"
                                checked={checkDisabled(
                                  info?.system_group,
                                  data?.disabled
                                )}
                                onChange={() => handleClick(info?.system_group)}
                              />
                              <label htmlFor="requisite-available"></label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </AnimatedBlock>
            )}
          </div>
        </div>

        <div className="project-currencies">
          <div
            className="currency-wrapper"
            style={{ padding: "30px 30px 60px" }}
          >
            <div className="custom-input custom-input_block">
              <label htmlFor="currency-id">ID проекта/магазина</label>
              <input
                value={shopId}
                type="text"
                id="currency-id"
                autoComplete="off"
                disabled
              />
            </div>
            <div className="custom-input custom-input_block">
              <label htmlFor="currency-name">Название</label>
              <input
                value={nameShop}
                type="text"
                id="currency-address"
                autoComplete="off"
                placeholder="Введите новое"
                onChange={(e) => setNameShop(e.target.value)}
              />
            </div>

            <div className="custom-input custom-input_block">
              <label htmlFor="currency-callback">Коллбек</label>
              <input
                value={urlCb}
                type="text"
                id="currency-callback"
                autoComplete="off"
                placeholder="https://..."
                onChange={(e) => setUrlCb(e.target.value)}
              />
            </div>

            <div className="custom-input custom-input_block">
              <label htmlFor="currency-res-callback">Результат</label>
              <input
                value={urlRes}
                type="text"
                id="currency-res-callback"
                autoComplete="off"
                placeholder="https://..."
                onChange={(e) => setUrlRes(e.target.value)}
              />
            </div>

            <div className="custom-input custom-input_block">
              <label>Оплата комиссии</label>
              <div className="custom-switch">
                <input
                  type="checkbox"
                  id="who-pay"
                  checked={comission}
                  onChange={() => setComission(!comission)}
                />
                <label htmlFor="who-pay">Покупатель</label>
              </div>
            </div>

            <div className="custom-input custom-input_block">
              <label htmlFor="secret-key">Секретный ключ</label>
              <input
                type={changeVisible ? "text" : "password"}
                id="secret-key"
                autoComplete="off"
                value={secretOne}
                onChange={(e) => setSecretOne(e.target.value)}
              />
              <button
                className="change-visibility"
                onClick={() => setChangeVisible(!changeVisible)}
              >
                <ChangeVisibleIcon />
              </button>
            </div>
            <button
              className="btn btn-success"
              style={{ marginTop: 25 }}
              onClick={() =>
                handleClickSave(
                  nameShop,
                  secretOne,
                  urlCb,
                  urlRes,
                  comission ? 1 : 0
                )
              }
              disabled={loadingSettings}
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCurrensies;
