import { useEffect, useState } from "react";
import { axiosConfig } from "../common/api";
import useAxios from "axios-hooks";
import { toast } from "react-toastify";
import { CopyIcon } from "./Icons";

const SettingKey = (props) => {
  const [loadingAll, setLoadingAll] = useState(false);
  const [visible, setVisible] = useState(false);

  const [{ data, loading }, refetch] = useAxios(
    "/merchant/api/key",
    axiosConfig
  );

  const [{ loading: loadingGenerate }, refetchGenerate] = useAxios(
    {
      url: `/merchant/api/key/generate`,
      method: "POST",
    },
    axiosConfig
  );

  useEffect(() => {
    setLoadingAll(loading || loadingGenerate);
  }, [loading, loadingGenerate]);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text); // Копирование текста в буфер обмена
      toast.success("Ключ скопирован"); // Сообщение об успешном копировании
    } catch (err) {
      toast.error("Ошибка при копировании"); // Сообщение об ошибке
    }
  };

  const handlerGenerate = () => {
    refetchGenerate({
      data: {
        api_key: data?.key,
      },
    })
      .then(() => {
        refetch();
        toast.success("Ключ обновлен");
      })
      .catch(() => {
        toast.error("Ошибка");
      });
  };

  useEffect(() => {
    refetch().catch((error) => {
      // console.log("refetch", error);
    });
  }, []);
  return (
    <div className="section-settings">
      <div className="card-block mt-25">
        <h4>Настройки API</h4>
        <span className="settings__api__label">Ваш ключ API</span>
        <div className="settings__api__key">
          {data?.key === null || data?.key === "" ? (
            <span>Сгенерируйте ключ</span>
          ) : (
            <>{visible ? <span>{data?.key}</span> : <span>ключ скрыт</span>}</>
          )}
          {data?.key !== null && data?.key !== "" && (
            <button
              onClick={() => copyToClipboard(data?.key)}
              className={loadingAll ? "_loading" : ""}
            >
              <CopyIcon />
            </button>
          )}
        </div>
        <div
          className={
            loadingAll ? "_loading settings__api__btns" : "settings__api__btns"
          }
        >
          <button
            onClick={handlerGenerate}
            className="btn btn-success btn_sm custom-input_block-btn settings__api__btn"
          >
            Сгенерировать новый ключ
          </button>
          <button
            onClick={() => setVisible(!visible)}
            className="btn btn-success btn_sm custom-input_block-btn settings__api__btn"
          >
            {visible ? "Скрыть" : "Показать"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingKey;
