import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { isLogin } from "../common/userApi";
import Header from "./Header";
import Aside from "./Aside";
import ShopList from "./ShopList";
import Settings from "./Settings";
import Shop from "./Shop";
import Wallet from "./Wallet";
import UserWithdraws from "./UserWithdraws";

const Dashboard = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [hamburgerActive, setHamburgerActive] = useState(false);

  configure({ axios: $authHost });

  const [{ data, loading, error }, refetch] = useAxios(
    "/merchant/shop/list",
    axiosConfig
  );

  useEffect(() => {
    setHamburgerActive(false);
    if (isLogin()) {
      refetch().catch((error) => {
        // console.log("refetch", error);
      });
    } else {
      navigate("/");
    }
  }, [params]);

  const isShopList = () => {
    return Object.keys(params).length === 0;
  };

  const isPage = (page) => {
    return Object.keys(params).length === 1 && params?.slug === page;
  };

  const isShopId = () => {
    return (
      Object.keys(params).length === 3 &&
      params?.slug === "shop" &&
      parseInt(params?.shopId) > 0
    );
  };

  const getShopId = () => {
    return parseInt(params?.shopId);
  };

  const getSlug = () => {
    return params?.slug ? params?.slug : "";
  };

  const getTab = () => {
    return params?.tab ? params?.tab : "";
  };

  return (
    <div className="container">
      <Header
        hamburgerActive={hamburgerActive}
        setHamburgerActive={setHamburgerActive}
        supportTelegramLink={data?.supportTelegramLink}
      />
      <Aside
        hamburgerActive={hamburgerActive}
        user={data?.user}
        slug={getSlug()}
        trade={false}
        supportTelegramLink={data?.supportTelegramLink}
      />
      <div className="main">
        {isShopList() && (
          <ShopList data={data} loading={loading} refetch={refetch} />
        )}
        {isPage("wallet") && <Wallet user={data?.user} />}
        {isPage("settings") && <Settings user={data?.user} />}
        {isPage("withdraws") && <UserWithdraws user={data?.user} />}
        {isShopId() && <Shop shopId={getShopId()} tab={getTab()} />}
      </div>
    </div>
  );
};

export default Dashboard;
