import { useEffect, useRef, useState } from "react";
import { BackIcon, RubbleIcon } from "../Icons";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../../common/api";
import { toast } from "react-toastify";
import Select from "../select/select";
import AutoComplete from "../AutoComplete";
import { getIconWithdrawIcon } from "../../common/utils";

export const ModalWithdraw = ({ active, setActive, info }) => {
  const [amountWallet, setAmountWallet] = useState("");
  const [methodPurseWallet, setMethodPurseWallet] = useState("");
  const [result, setResult] = useState(0);
  const [commissionType, setCommissionType] = useState(0);

  const [methodNameWallet, setMethodNameWallet] = useState({
    id: null,
    label: "Выберите",
  });

  const handleSwitch = () => {
    setCommissionType(commissionType === 0 ? 1 : 0);
  };
  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: `/merchant/shop/withdraws/create`,
      method: "POST",
    },
    axiosConfig
  );

  const [{ data }, getMethods] = useAxios(
    {
      url: `/merchant/withdraws/methods`,
      method: "GET",
    },
    axiosConfig
  );

  function summaResultFromWallet(amount, percent, fix) {
    fix = fix || 0;
    percent = percent || 0;
    let result = +fix + (+amount / 100) * +percent;

    return result ? +result : 0;
  }
  useEffect(() => {
    if (!active) {
      setAmountWallet("");
      setMethodPurseWallet("");
      setMethodNameWallet({
        id: null,
        label: "Выберите",
      });
    }
  }, [active, setAmountWallet, setMethodPurseWallet, setMethodNameWallet]);

  const handleSubmit = async () => {
    await executePost({
      data: {
        amount: result,
        method_id: methodNameWallet.id,
        purse: methodPurseWallet,
      },
    })
      .then((data) => {
        setActive(false);
        toast.success("Заявка успешно добавлена");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  const handleChangeAmountWallet = (value) => {
    setAmountWallet(value.toString().replace(/,/g, "."));
  };

  function filterByRegex(pattern, elements) {
    const regex = new RegExp(pattern);
    return elements ? elements.filter((element) => regex.test(element)) : null;
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getMethods();
      } catch (err) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (commissionType === 0) {
      setResult(
        (
          +amountWallet +
          summaResultFromWallet(
            amountWallet,
            methodNameWallet?.commission_percent,
            methodNameWallet?.commission_fix
          )
        ).toFixed(2)
      );
    } else {
      setResult(Number(amountWallet).toFixed(2));
    }
  }, [methodNameWallet, amountWallet, commissionType]);
  return (
    <div className={active ? "popup open" : "popup"}>
      <div className="popup__body" onClick={() => setActive(false)}>
        <div
          className="popup__content popup__withdraw"
          onClick={(e) => e.stopPropagation(e)}
        >
          <div className="popup__header">
            <span>Вывод средств</span>
            <button onClick={() => setActive(false)}>
              <BackIcon />
            </button>
          </div>
          <div className="popup__balance">
            <p>Ваш баланс</p>
            <span>{info?.financial_balance} р</span>
          </div>
          <Select
            value={methodNameWallet}
            setValue={setMethodNameWallet}
            data={data?.methods}
            getIcon={getIconWithdrawIcon}
          />
          <div className="popup__form">
            <div className="input-block">
              <span>Номер кошелька</span>
              <AutoComplete
                disabled={methodNameWallet.id === null}
                value={methodPurseWallet}
                setValue={setMethodPurseWallet}
                regexValue={methodNameWallet?.regex}
                id={methodNameWallet.id}
              />
            </div>
            <div className="input-block input-block-mb">
              <span>Сумма вывода</span>
              <div className="input-container">
                <button
                  onClick={() => {
                    if (commissionType === 0) {
                      let resultMax =
                        +info?.financial_balance -
                        summaResultFromWallet(
                          +info?.financial_balance,
                          methodNameWallet?.commission_percent,
                          methodNameWallet?.commission_fix
                        );
                      handleChangeAmountWallet(resultMax);
                    } else {
                      handleChangeAmountWallet(info?.financial_balance);
                    }
                  }}
                >
                  MAX
                </button>
                <input
                  value={amountWallet}
                  type="number"
                  id="withdraw-amount"
                  placeholder="Введите данные"
                  autoComplete="off"
                  onChange={(e) => handleChangeAmountWallet(e.target.value)}
                />
                <RubbleIcon className="small-icon" />
              </div>
            </div>
            <div className="withdraw__switch">
              <span>Списать комиссию c суммы платежа </span>
              <label className="switch">
                <input type="checkbox" onChange={handleSwitch} />
                <span></span>
              </label>
            </div>
            <div className="input-block">
              <span>Сумма к списанию</span>
              <div className="input-container">
                <input
                  value={result}
                  type="text"
                  id="withdraw-amount"
                  placeholder="Введите данные"
                  onChange={(e) => e.target.value}
                  readOnly
                />
                <RubbleIcon className="small-icon" />
              </div>
            </div>
            <button
              className="popup__bnt"
              disabled={loading}
              onClick={() => {
                handleSubmit(amountWallet, methodNameWallet, methodPurseWallet);
              }}
            >
              Создать заявку
            </button>
            {methodNameWallet.id && methodNameWallet.id !== 0 ? (
              <>
                <span className="commissions">
                  Комиссия: {methodNameWallet?.commission_percent}% +{" "}
                  {methodNameWallet?.commission_fix} ₽ / Лимит одного вывода:{" "}
                  {methodNameWallet.min} ₽ - {methodNameWallet.max} ₽
                </span>
              </>
            ) : (
              <>
                <div className="commissions">
                  <span>Выберите метод</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWithdraw;
