import { useEffect, useState } from "react";
import { getIconWithdrawIcon } from "../common/utils";

const AutoComplete = ({ value, setValue, regexValue, id, disabled }) => {
  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Проверяем регулярное выражение, если оно задано
    if (regexValue) {
      const regex = new RegExp(regexValue);
      if (regex.test(inputValue) || inputValue === "") {
        setValue(inputValue);
      }
    } else {
      setValue(inputValue);
    }
  };

  useEffect(() => {
    setValue("");
  }, [regexValue]);
  return (
    <>
      <div className="input-container">
        <input
          disabled={disabled}
          type="text"
          value={value}
          onChange={handleChange}
          placeholder="Введите"
        />
        {getIconWithdrawIcon(id)}
      </div>
    </>
  );
};

export default AutoComplete;
