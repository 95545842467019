import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { apiData, customStyle } from "../common/apiData";
import { SelectIcon } from "./Icons";
import { toast } from "react-toastify";
import { BASE_PATH_API } from "../common/constants";
const Api = () => {
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text); // Копирование текста в буфер обмена
      toast.success("Путь скопирован"); // Сообщение об успешном копировании
    } catch (err) {
      toast.error("Ошибка при копировании"); // Сообщение об ошибке
    }
  };
  return (
    <div className="apiDocs">
      <div className="container apiDocs__container">
        <h1 className="apiDocs__title">Документация API</h1>
        <div className="apiDocs__block">
          <h2 className="apiDocs__subtitle">Merchant</h2>
          <Accordion
            allowMultiple={true}
            transition={true}
            transitionTimeout={200}
            className="apiDocs__content"
          >
            {apiData.Merchant.map((item) => (
              <AccordionItem
                key={item.path}
                className="api__point"
                header={
                  <div className="api__header">
                    <span className="apiDocs__name">{item.title}</span>
                    <SelectIcon />
                  </div>
                }
              >
                <div className="api__body">
                  {item.path && (
                    <span
                      className="api__path"
                      onClick={() => copyToClipboard(BASE_PATH_API + item.path)}
                    >
                      {BASE_PATH_API + item.path}
                    </span>
                  )}
                  <p
                    className="api__text"
                    dangerouslySetInnerHTML={{
                      __html: item.description[0],
                    }}
                  ></p>
                  {item?.docs && (
                    <>
                      <div className="api__items">
                        <div className="api__names">
                          <span>Name</span>
                          <span>Type</span>
                          <span>Default</span>
                          <span>Description</span>
                        </div>
                        {item.docs.map((doc) => (
                          <div key={doc.name} className="api__item">
                            <div>
                              <p>{doc.isRequire ? "*" : ""}</p>
                              <span>{doc.name}</span>
                            </div>
                            <span className="api__type">{doc.type}</span>
                            <span className="api__default">{doc.default}</span>
                            <p
                              className="api__description"
                              dangerouslySetInnerHTML={{
                                __html: doc.description,
                              }}
                            ></p>
                          </div>
                        ))}
                      </div>
                      <span className="api__text">{item.docsDescription}</span>
                    </>
                  )}
                  {item.response && (
                    <>
                      <p
                        className="api__text"
                        dangerouslySetInnerHTML={{
                          __html: item.description[1],
                        }}
                      ></p>
                      <div className="code">
                        <SyntaxHighlighter
                          language="javascript"
                          style={customStyle}
                          className="code__content"
                        >
                          {item.response}
                        </SyntaxHighlighter>
                      </div>
                    </>
                  )}
                  {item.description[2] && (
                    <p
                      className="api__text"
                      dangerouslySetInnerHTML={{
                        __html: item.description[2],
                      }}
                    ></p>
                  )}
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="apiDocs__block">
          <h2 className="apiDocs__subtitle">Shop</h2>
          <Accordion
            allowMultiple={true}
            transition={true}
            transitionTimeout={200}
            className="apiDocs__content"
          >
            {apiData.Shop.map((item) => (
              <AccordionItem
                key={item.path}
                className="api__point"
                header={
                  <div className="api__header">
                    <span className="apiDocs__name">{item.title}</span>
                    <SelectIcon />
                  </div>
                }
              >
                <div className="api__body">
                  {item.path && (
                    <span
                      className="api__path"
                      onClick={() => copyToClipboard(BASE_PATH_API + item.path)}
                    >
                      {BASE_PATH_API + item.path}
                    </span>
                  )}
                  <p
                    className="api__text"
                    dangerouslySetInnerHTML={{
                      __html: item.description[0],
                    }}
                  ></p>
                  {item?.docs && (
                    <>
                      <div className="api__items">
                        <div className="api__names">
                          <span>Name</span>
                          <span>Type</span>
                          <span>Default</span>
                          <span>Description</span>
                        </div>
                        {item.docs.map((doc) => (
                          <div key={doc.name} className="api__item">
                            <div>
                              <p>{doc.isRequire ? "*" : ""}</p>
                              <span>{doc.name}</span>
                            </div>
                            <span className="api__type">{doc.type}</span>
                            <span className="api__default">{doc.default}</span>
                            <p
                              className="api__description"
                              dangerouslySetInnerHTML={{
                                __html: doc.description,
                              }}
                            ></p>
                          </div>
                        ))}
                      </div>
                      <span className="api__text">{item.docsDescription}</span>
                    </>
                  )}
                  {item.response && (
                    <>
                      <p
                        className="api__text"
                        dangerouslySetInnerHTML={{
                          __html: item.description[1],
                        }}
                      ></p>
                      <div className="code">
                        <SyntaxHighlighter
                          language="javascript"
                          style={customStyle}
                          className="code__content"
                        >
                          {item.response}
                        </SyntaxHighlighter>
                      </div>
                    </>
                  )}
                  {item.description[2] && (
                    <p
                      className="api__text"
                      dangerouslySetInnerHTML={{
                        __html: item.description[2],
                      }}
                    ></p>
                  )}
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Api;
