export const customStyle = {
  'code[class*="language-"]': {
    color: "#dbdbdb",
    background: "none",
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    fontSize: ".75em",
    lineHeight: "1",
    direction: "ltr",
    textAlign: "left",
    whiteSpace: "pre",
    wordSpacing: "normal",
    wordBreak: "normal",
    tabSize: "4",
    hyphens: "none",
  },
};

export const apiData = {
  Merchant: [
    {
      title: "Аутентификация запроса",
      path: null,
      docs: [
        {
          name: "x-user",
          isRequire: true,
          type: "INT",
          default: null,
          description: "Id пользователя",
        },
        {
          name: "x-key",
          isRequire: true,
          type: "STRING",
          default: null,
          description: "API ключ пользователя",
        },
      ],
      docsDescription: null,
      description: [
        `Для выполнение запросов связанных с user-merchant нужно передать в headers поля user_id и api_key`,
        "",
        "",
      ],
    },
    {
      title: "Создать вывод средств",
      path: "/merchant/withdraw/create/api",
      docs: [
        {
          name: "amount",
          isRequire: true,
          type: "FLOAT",
          default: null,
          description: "Сумма вывода",
        },
        {
          name: "method_id",
          isRequire: true,
          type: "INT",
          default: null,
          description: "Id метода вывода",
        },
        {
          name: "purse",
          isRequire: true,
          type: "STRING",
          default: null,
          description: "Реквизиты кошелька для вывода",
        },
      ],
      docsDescription: "* Данные поля нужно передать в body запроса",
      description: [
        `Вывод средств с внутреннего кошелька user-merchant на внешние кошельки<br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "<span>amount_to_pay</span> - Сумма которая будет зачислена, с учетом комиссии<br><span>status: 0</span> - В ожидании<br>",
      ],
      response: `{
	"success": 1,
	"withdraw": {
		"note": {},
		"provider_id": 0,
		"status": 0,
		"id": 1,
		"user_id": 1,
		"method_id": 1,
		"shop_id": 1,
		"amount": 100,
		"amount_to_pay": 88,
		"purse": "1234",
		"updatedAt": "2000-00-00T00:00:00.000Z",
		"createdAt": "2000-00-00T00:00:00.000Z"
	}
}`,
    },
    {
      title: "Информация о выводе",
      path: "/merchant/withdraw/info/:id/api",
      docs: null,
      docsDescription: null,
      description: [
        `Получение информации вывода средства с кошелька user-merchant<br>Метод: <span>GET</span>`,
        "Пример ответа с сервера",
        "<span>amount_to_pay</span> - Сумма которая будет зачислена, с учетом комиссии<br><span>status: -3</span> - Отменено мерчантом<br><span>status: -2</span> - Отменено администратором<br><span>status: -1</span> - Отменено пользователем<br><span>status: 0</span> - Создан<br><span>status: 1</span> - В процессе<br><span>status: 2</span> - Успешно",
      ],
      response: `{
	"success": 1,
	"withdraw": {
		"note": {},
		"id": 1,
		"user_id": 1,
		"shop_id": 1,
		"method_id": 1,
		"provider_id": 0,
		"amount": 100,
		"amount_to_pay": 88,
		"status": 0,
		"updatedAt": "2000-00-00T00:00:00.000Z",
		"createdAt": "2000-00-00T00:00:00.000Z"
	}
}`,
    },
    {
      title: "Список выводов",
      path: "/merchant/withdraw/list/api",
      docs: [
        {
          name: "page",
          isRequire: false,
          type: "INT",
          default: 1,
          description: "Номер страницы",
        },
        {
          name: "count",
          isRequire: false,
          type: "INT",
          default: 10,
          description: "Количество выводов на странице",
        },
        {
          name: "status",
          isRequire: false,
          type: "INT",
          default: null,
          description: "Статус вывода",
        },
        {
          name: "date_start",
          isRequire: false,
          type: "DATE",
          default: null,
          description: "Выбрать записи позднее <span>date_start</span>",
        },
        {
          name: "date_end",
          isRequire: false,
          type: "DATE",
          default: null,
          description: "Выбрать записи ранее <span>date_end</span>",
        },
        {
          name: "order_id",
          isRequire: false,
          type: "STRING",
          default: null,
          description: "Поиск по id",
        },
      ],
      docsDescription: "* Данные поля нужно передать в body запроса",
      description: [
        `Получение списка о выводах средств с кошелька user-merchant<br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "<span>count_withdraws</span> - Количество страниц",
      ],
      response: `{
	"success": 1,
	"withdraws": [...],
	"count_withdraws": 14
}`,
    },
    {
      title: "Получение баланса",
      path: "/merchant/balance/api",
      docs: [
        {
          name: "shop_id",
          isRequire: false,
          type: "INT",
          default: null,
          description: "Id магазина",
        },
      ],
      docsDescription: "* Данные поля нужно передать в body запроса",
      description: [
        `Получение баланса user-merchant и список балансов всех магазинов. Передав shop_id в ответ придет баланс данного магазина<br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "",
      ],
      response: `{
	"success": 1,
	"user": {
		"balance": 1000
	},
	"shops": [
		{
			"id": 1,
			"balance": 100
		}
	]
}`,
    },
    {
      title: "Методы для вывода",
      path: "/merchant/withdraws/methods/api",
      docs: null,
      docsDescription: null,
      description: [
        `Список активных методов для вывода. Не требует Аутентификации <br>Метод: <span>GET</span>`,
        "Пример ответа с сервера",
        "<span>purseValues</span> - массив с последними purses, которые вводил user-merchant",
      ],
      response: `{
	"success": 1,
	"methods": [
		{
			"id": 1,
			"name": "Test",
			"label": "test",
			"regex": "^[0-9]+$",
			"min": 100,
			"max": 1000,
			"commission_fix": 10,
			"commission_percent": 2,
			"createdAt": "2024-09-20T13:17:28.000Z",
			"updatedAt": "2024-09-22T19:53:24.000Z"
		}
	],
	"purseValues": [...]
}`,
    },
  ],
  Shop: [
    {
      title: "Аутентификация запроса",
      path: null,
      docs: [
        {
          name: "x-shop",
          isRequire: true,
          type: "INT",
          default: null,
          description: "Id магазина",
        },
        {
          name: "x-secret",
          isRequire: true,
          type: "STRING",
          default: null,
          description: "Секретный ключ магазина",
        },
      ],
      docsDescription: null,
      description: [
        `Для выполнение запросов связанных с магазином нужно передать в headers поля shop_id и secret`,
        "",
        "",
      ],
    },
    {
      title: "Создание ордера (Описать)",
      path: "/merchant/order/create/api",
      docs: [
        {
          name: "merchant_order_id",
          isRequire: false,
          type: "INT",
          default: null,
          description: "Id магазина",
        },
      ],
      docsDescription: "* Данные поля нужно передать в body запроса",
      description: [
        `Создание ордера<br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "",
      ],
      response: `{
	"success": 1,
	"user": {
		"balance": 1000
	},
	"shops": [
		{
			"id": 1,
			"balance": 100
		}
	]
}`,
    },
    {
      title: "Информация о ордере",
      path: "/merchant/order/info/:guid/api",
      docs: null,
      docsDescription: null,
      description: [
        `Информация о конкретном ордере по guid<br>Метод: <span>GET</span>`,
        "Пример ответа с сервера",
        "",
      ],
      response: `{
	"success": 1,
	"payment": {
		"id": 22071,
		"guid": "11111111-1111-1111-1111-1111111111111",
		"provider_id": "0",
		"merchant_id": "item001",
		"amount": 100,
		"amount_to_pay": 100,
		"method": "card1",
		"status": -2,
		"method_group": "card",
		"cretedAt": "2000-00-00T00:00:00.000Z",
		"expired": "2000-00-00T00:00:00.000Z",
		"result_url": "https://example.com/success.html?payment_id=22071&merchant_order_id=item001",
		"note": {
			"pan": "0000000000000",
			"fio": "Иванов Иван Иванович",
			"type": "card",
			"comment": "0"
		}
	},
	"methods": [
		{
			"system_group": "system",
			"comission": 3,
			"min": 100,
			"max": 50000
		}
	],
	"url": "https://example.com/pay/f7d8b8d2-a301-5c7c-a26c-b1bba0d3f9a6",
	"supportTelegramLink": "https://example.com",
	"shop": {
		"name": "ShopName"
	},
	"need_to_select_bank": [
		"card"
	],
	"banksList": [
		{
			"id": 1,
			"name": "Банк",
			"alias": "Bank"
		}
	]
}`,
    },
    {
      title: "Список ордеров",
      path: "/merchant/order/list/api",
      docs: [
        {
          name: "page",
          isRequire: false,
          type: "INT",
          default: 1,
          description: "Номер страницы",
        },
        {
          name: "count",
          isRequire: false,
          type: "INT",
          default: 10,
          description: "Количество выводов на странице",
        },
        {
          name: "status",
          isRequire: false,
          type: "INT",
          default: null,
          description: "Статус вывода",
        },
        {
          name: "date_start",
          isRequire: false,
          type: "DATE",
          default: null,
          description: "Выбрать записи позднее <span>date_start</span>",
        },
        {
          name: "date_end",
          isRequire: false,
          type: "DATE",
          default: null,
          description: "Выбрать записи ранее <span>date_end</span>",
        },
        {
          name: "amount_start",
          isRequire: false,
          type: "INT",
          default: null,
          description:
            "Выбрать записи, где amount >  <span>amount_start</span>",
        },
        {
          name: "amount_end",
          isRequire: false,
          type: "INT",
          default: null,
          description: "Выбрать записи, где amount <  <span>amount_end</span>",
        },
      ],
      docsDescription: "* Данные поля нужно передать в body запроса",
      description: [
        `Получение списка о выводах ордеров<br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "<span>count_payments</span> - Количество страниц",
      ],
      response: `{
	"success": 1,
	"payments": [...],
	"count_payments": 14
}`,
    },
    {
      title: "Создать вывод средств",
      path: "/merchant/withdraw/shop/create/api",
      docs: [
        {
          name: "amount",
          isRequire: true,
          type: "FLOAT",
          default: null,
          description: "Сумма вывода",
        },
        {
          name: "method_id",
          isRequire: true,
          type: "INT",
          default: null,
          description:
            "Id метода вывода. <span>Чтобы вывести средства на внутренний кошелек user-merchant передайте значение: 0</span>",
        },
        {
          name: "purse",
          isRequire: true,
          type: "STRING",
          default: null,
          description:
            "Реквизиты кошелька для вывода. <span>Не обязателен если перевод на внутренний кошелек</span>",
        },
      ],
      docsDescription: "* Данные поля нужно передать в body запроса",
      description: [
        `Вывод средств с баланса <br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "<span>amount_to_pay</span> - Сумма которая будет зачислена, с учетом комиссии<br><span>status: 0</span> - В ожидании<br>",
      ],
      response: `{
	"success": 1,
	"withdraw": {
		"note": {},
		"provider_id": 0,
		"status": 0,
		"id": 1,
		"user_id": 1,
		"method_id": 1,
		"shop_id": 1,
		"amount": 100,
		"amount_to_pay": 88,
		"purse": "1234",
		"updatedAt": "2000-00-00T00:00:00.000Z",
		"createdAt": "2000-00-00T00:00:00.000Z"
	}
}`,
    },
    {
      title: "Информация о выводе",
      path: "/merchant/shop/withdraw/info/:id/api",
      docs: null,
      docsDescription: null,
      description: [
        `Получение информации вывода средства с магазина<br>Метод: <span>GET</span>`,
        "Пример ответа с сервера",
        "<span>amount_to_pay</span> - Сумма которая будет зачислена, с учетом комиссии<br><span>status: -3</span> - Отменено мерчантом<br><span>status: -2</span> - Отменено администратором<br><span>status: -1</span> - Отменено пользователем<br><span>status: 0</span> - Создан<br><span>status: 1</span> - В процессе<br><span>status: 2</span> - Успешно",
      ],
      response: `{
	"success": 1,
	"withdraw": {
		"note": {},
		"id": 1,
		"user_id": 1,
		"shop_id": 1,
		"method_id": 1,
		"provider_id": 0,
		"amount": 100,
		"amount_to_pay": 88,
		"status": 0,
		"updatedAt": "2000-00-00T00:00:00.000Z",
		"createdAt": "2000-00-00T00:00:00.000Z"
	}
}`,
    },
    {
      title: "Список выводов",
      path: "/merchant/shop/withdraw/list/api",
      docs: [
        {
          name: "page",
          isRequire: false,
          type: "INT",
          default: 1,
          description: "Номер страницы",
        },
        {
          name: "count",
          isRequire: false,
          type: "INT",
          default: 10,
          description: "Количество выводов на странице",
        },
        {
          name: "status",
          isRequire: false,
          type: "INT",
          default: null,
          description: "Статус вывода",
        },
        {
          name: "date_start",
          isRequire: false,
          type: "DATE",
          default: null,
          description: "Выбрать записи позднее <span>date_start</span>",
        },
        {
          name: "date_end",
          isRequire: false,
          type: "DATE",
          default: null,
          description: "Выбрать записи ранее <span>date_end</span>",
        },
        {
          name: "order_id",
          isRequire: false,
          type: "STRING",
          default: null,
          description: "Поиск по id",
        },
      ],
      docsDescription: "* Данные поля нужно передать в body запроса",
      description: [
        `Получение списка о выводах средств с кошелька user-merchant<br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "<span>count_withdraws</span> - Количество страниц",
      ],
      response: `{
	"success": 1,
	"withdraws": [...],
	"count_withdraws": 14
}`,
    },
    {
      title: "Создание апелляции",
      path: "/merchant/appeals/create/api",
      docs: [
        {
          name: "payment_id",
          isRequire: true,
          type: "INT",
          default: null,
          description: "Id платежа",
        },
        {
          name: "note",
          isRequire: false,
          type: "STRING",
          default: null,
          description: "Комментарий",
        },
        {
          name: "file",
          isRequire: true,
          type: "FILE",
          default: null,
          description: "Чек платежа (png,jpeg,pdf,x-pdf)",
        },
      ],
      docsDescription: "* Данные поля нужно передать через formData",
      description: [
        `Создание апелляции для платежа<br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "",
      ],
      response: `{
	"success": 1,
}`,
    },
    {
      title: "Информация о апелляции",
      path: "/merchant/appeals/info/:id/api",
      docs: null,
      docsDescription: null,
      description: [
        `Информация о конкретной апелляции<br>Метод: <span>GET</span>`,
        "Пример ответа с сервера",
        "",
      ],
      response: `{
	"success": 1,
	"appeal": {
		"id": 1,
		"payment_id": 1,
		"url": "media/example.png",
		"status": 0,
		"note": "",
		"trader_note": "",
		"createdAt": "2000-00-00T00:00:00.000Z",
		"updatedAt": "2000-00-00T00:00:00.000Z",
		"payment_note": {
			"pan": "11111111111",
			"fio": "Иванов Иван Иваныч",
			"type": "card",
			"comment": "0"
		}
	}
}`,
    },
    {
      title: "Список апелляций",
      path: "/merchant/appeals/list/api",
      docs: [
        {
          name: "page",
          isRequire: false,
          type: "INT",
          default: 1,
          description: "Номер страницы",
        },
        {
          name: "count",
          isRequire: false,
          type: "INT",
          default: 10,
          description: "Количество выводов на странице",
        },
        {
          name: "date_start",
          isRequire: false,
          type: "DATE",
          default: null,
          description: "Выбрать записи позднее <span>date_start</span>",
        },
        {
          name: "date_end",
          isRequire: false,
          type: "DATE",
          default: null,
          description: "Выбрать записи ранее <span>date_end</span>",
        },
        {
          name: "order_id",
          isRequire: false,
          type: "STRING",
          default: null,
          description: "Поиск по id",
        },
      ],
      docsDescription: "* Данные поля нужно передать в body запроса",
      description: [
        `Получение списка апелляций<br>Метод: <span>POST</span>`,
        "Пример ответа с сервера",
        "<span>count_appeals</span> - Количество страниц",
      ],
      response: `{
	"success": 1,
	"appeals": [...],
	"count_appeals": 14
}`,
    },
  ],
};
